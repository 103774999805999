@use "../../styles/index.scss" as globals;

.header {
    background: globals.$color_black;
    border-radius: 15px;
    margin: auto;
    margin-top: 10px;
    display: flex;
    gap: 0.5rem;
    padding: 9px;
    @include globals.box-shadow-default();
;
}