.settings-menu-container {
    position: relative;

    .settings-menu {
        margin-right: -10px;

        &::before {
            right: 30px;
        }
    }
}
