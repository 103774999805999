@use "./index.scss" as globals;

$control_background: transparent;
$control_color_enabled: globals.$color_white;
$control_color_disabled: globals.$color_gray;

@mixin control-base {
    background-color: $control_background;
    color: $control_color_enabled;
    opacity: .7;
    border: none;
    outline: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    svg {
        color: $control_color_enabled;
    }


    &:disabled {
        opacity: .7;
        svg {
            color: $control_color_disabled;
        }

        .badge {
            background-color: $control_color_disabled;
        }
    }
}