.animate {
    transform: translateX(-100%);
  
    &.slide-in {
      transform: translateX(-100%);
    }
  
    &.slide-out {
      transform: translateX(0%);
    }
  }
  
  .slide-in {
    animation: slide-in .5s forwards;
  }
  
  .slide-out {
    animation: slide-out .5s forwards;
  }
  
  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }
  
  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
  
    100% {
      transform: translateX(200%);
    }
  }