$color-platinum: #E5E4E2;
$color-nero: var(--color-nero);
$color-black: black;
$color-white: var(--color-white);
$color-gray: #696969;
$color-lime: lime;

// liquid colors
$color-cadium-orange: #F28C28;
$color-tomato: #ff6347;
$color-pink: #ffc0cb;
$color-baby-blue: #89CFF0;
$color-blue: #115dd2;
$color-golden-yellow: #FFC000;
$color-green: #27AE60;
$color-mint-green: #98FB98;
$color-ash-gray: #B2BEB5;
$color-brown: #6f4e37;


body {
  background-image: url('../images/bg-1.svg');
}

@mixin for-modile-landscape {
   @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-height: 600px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin box-shadow-default {
  box-shadow: 0 7px 15px rgba($color-black, .5);
}

.game {
  display: flex;
  flex-direction: column;
  margin: auto;

  @include for-tablet-landscape-up {
    width: 60%;
  }
}