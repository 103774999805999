@use "../../../styles/index.scss" as globals;

$liquid-level-change-animation-duration: .4s;

.capacity-2 {
    .liquid {
        --animation-height: 50%;
        height: 50%;
        max-height: 50%;
    }
}

.capacity-3 {
    .liquid {
        --animation-height: 33.3%;
        height: 33%;
        max-height: 33%;
    }
}

.capacity-4 {
    .liquid {
        --animation-height: 25%;
        height: 25%;
        max-height: 25%;
    }
}

.liquid {
    margin-bottom: -1px;

    &:first-child {
        border-radius: inherit;
        margin-bottom: 0;
    }

    &.liquid-level-up-animation {
        animation: liquid-level-up $liquid-level-change-animation-duration linear;

        @keyframes liquid-level-up {
            from {
                max-height: 0px;
            }

            to {
                max-height: var(--animation-height);
            }
        }
    }

    &.liquid-level-down-animation {
        animation: liquid-level-down $liquid-level-change-animation-duration linear;

        @keyframes liquid-level-down {
            from {
                max-height: var(--animation-height);
            }

            to {
                max-height: 0px;
            }
        }
    }

    &.orange {
        background-color: globals.$color-cadium-orange;
    }

    &.red {
        background-color: globals.$color-tomato;
    }

    &.pink {
        background-color: globals.$color-pink;
    }

    &.light-blue {
        background-color: globals.$color-baby-blue;
    }

    &.dark-blue {
        background-color: globals.$color-blue;
    }

    &.yellow {
        background-color: globals.$color-golden-yellow;
    }

    &.green {
        background-color: globals.$color-green;
    }

    &.light-green {
        background-color: globals.$color-mint-green;
    }

    &.gray {
        background-color: globals.$color-ash-gray;
    }

    &.brown {
        background-color: globals.$color-brown;
    }
}