@use "../../../styles/index.scss" as globals;

.switch {
    display: flex;
    flex-direction: row;
    width: 3.25rem;
    height: 1.35rem;
    border-radius: 50px;
    cursor: pointer;
    padding: 3px;

    justify-content: start;

    background-color: #4E4949;
    box-shadow: inset 0 0 3px rgba(black, 0.3);

    &::before {
        content: ' ';
        height: 100%;
        aspect-ratio: 1;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 3px rgba(black, 0.3);
    }

    &.checked {
        background-color: #26aa5a;
        justify-content: end;
    }

}