@use "../../../styles/index.scss" as globals;

.bubble {
    position: absolute;
    margin: 0;
    padding: 0;
    aspect-ratio: 1/1;
    border-radius: 50%;
    opacity: 0;
    border: 1px solid white;

    @keyframes bubble_animation {
        0%,
        100% {
            opacity: 0
        }

        50% {
            opacity: 1
        }
    }

    animation-name: bubble_animation;
    animation-direction: alternate;
    animation-duration: .75s;
    animation-iteration-count: 1;

    @include globals.for-tablet-landscape-up {
        &.bubble-xs {
            width: .75rem;
        }

        &.bubble-s {
            width: 1.25rem;
        }

        &.bubble-md {
            width: 1.75rem;
        }

        &.bubble-lg {
            width: 2.25rem;
        }
    }

    &.xs {
        width: .5rem;
    }

    &.s {
        width: .75rem;
    }

    &.md {
        width: 1.25rem;
    }

    &.lg {
        width: 1.75rem;
    }

    &:nth-of-type(1) {
        top: -19%;
        left: 28%;
        animation-delay: 1.35s;
    }

    &:nth-of-type(2) {
        top: -15%;
        left: 15%;
        animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
        top: -9%;
        right: 12%;
        animation-delay: 1.05s;
    }

    &:nth-of-type(4) {
        top: 10%;
        left: 10%;
        animation-delay: .9s;
    }

    &:nth-of-type(5) {
        top: 25%;
        right: 12%;
        animation-delay: .75s;
    }

    &:nth-of-type(6) {
        top: 35%;
        right: 10%;
        animation-delay: .6s;
    }

    &:nth-of-type(7) {
        top: 45%;
        left: 12%;
        animation-delay: .45s;
    }

    &:nth-of-type(8) {
        top: 60%;
        right: 22%;
        animation-delay: .3s;
    }

    &:nth-of-type(9) {
        top: 75%;
        left: 22%;
        animation-delay: .15s;
    }

    &:nth-of-type(10) {
        top: 78%;
        left: 23%;
    }
}
