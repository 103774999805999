@use "../../../styles/index.scss" as globals;

@mixin button-defaults {
    display: block;
    font-size: .8rem;
    font-weight: 700;
    border-radius: 12px;
    border: none;
    padding: .9rem 2.5rem;
    box-shadow: 0px 15px 25px globals.$color-black;
    text-transform: uppercase;

    svg {
        display: inline-block;
        padding-left: 10px;
    }

    &:hover {
        opacity: .75;
    }
}

button {
    &.main {
        @include button-defaults();
        color: globals.$color-white;
        background: linear-gradient(to right, globals.$color-golden-yellow, globals.$color-tomato);
    }

    &.secondary {
        @include button-defaults();
        background: none;
        background-color: #FFF5EE;
        color: globals.$color-black;
    }
}