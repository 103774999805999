@use "../../../styles/index.scss" as globals;

$badge-update-color: globals.$color-lime;
$animation-duration: 1.25s;

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 1.15rem;
  aspect-ratio: 1/1;
  color: globals.$color_black;
  background-color: globals.$color_white;
  font-size: 0.8rem;
  font-weight: 700;

  &.update {
    animation: blink $animation-duration ease 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      
      animation: pulse $animation-duration ease 1;
    }
  }
}

  @keyframes pulse {
    0% {
      border: 4px double lighten($badge-update-color, 20%);
      transform: scale(1);
      opacity: 1;
    }
    60% {
      border: 4px double lighten($badge-update-color, 20%);
      transform: scale(1.3);
      opacity: 0.4;
    }
    100% {
      border: 4px double lighten($badge-update-color, 20%);
      transform: scale(1.4);
      opacity: 0;
    }
  }

  @keyframes blink {
    0% {
      background-color: lighten($badge-update-color, 20%);
    }
    60% {
      background-color: lighten($badge-update-color, 20%);
    }
    100% {
      background-color: globals.$color-white;
    }
  }
