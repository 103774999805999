@use '../../../styles/control.scss' as control;

.control-bar {
  display: flex;
  gap: 0;

  .control-bar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.25rem;
    width: 4rem;
    position: relative;
    z-index: 1000;

    @include control.control-base();

    &.small {
      width: 3.25rem;
    }

    .pie-chart {
      position: absolute;
      top: 0;
      right: 0; 
    }

    >.badge {
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
}