@use "../../styles/index.scss" as globals;


.level-done {
    flex-grow: 1;
    padding: 9dvh 0;

    display: grid;
    grid-template-rows: min-content .6fr .5fr min-content;
    justify-items: center;
    align-items: center;

    .text {
        text-align: center;
    }

    h2 {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 1.15rem;
        font-weight: 700;
        text-transform: uppercase;
        color: darken(white, 15);
    }

    p {
        padding: 5px 0;
        margin: 0;
        color: darken(white, 40);
        font-size: .9rem;
        max-width: 250px;
    }

    img, svg {
        object-fit: contain;
        grid-row: 2/4;
    }

    img {
        height: 47dvh;
    }

    .medal {
        height: 28dvh;
    }

    button {
        position: relative;
        z-index: 1000;
    }


    @include globals.for-modile-landscape {
        padding: 0;
        grid-template-columns: 1fr 1fr;
        gap: 1rem 3rem;

        .text {
            text-align: left;
        }

        img, svg {
            grid-column: 1;
            grid-row: 1/5;
            justify-self: end;
            align-self: center;
        }

        img {
            height: 60dvh;
        }   

        .medal {
            height: 45dvh;
        }

        h2 {
            font-size: 1.15rem;
        }

        .text {
            grid-row: 2;
            justify-self: start;
            align-self: end;
        }

        .text,
        button {
            grid-column: 2;
        }

        button {
            grid-row: 3;
            align-self: start;
            justify-self: start;
        }
    }
}