@use "../../../styles/index.scss" as globals;

.puzzle-area {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 2% 3.5%;

  @include globals.for-tablet-portrait-up {
    gap: 2%;
  }
}