@use "../../../styles/index.scss" as globals;

@mixin text-gradient {
    background-image: linear-gradient(to right, globals.$color-golden-yellow, globals.$color-tomato);
    color: transparent;
    background-clip: text;
}

.level {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 12px;
    opacity: .75;
    background-color: globals.$color_nero;
    text-decoration: none;

    .level-number {
        justify-self: right;
        padding: 0;
        padding: 0;
        text-align: center;
        font-size: 1.45rem;
        line-height: 1.45rem;
        font-weight: 700;
        @include text-gradient();
    }

    .text {
        text-transform: uppercase;
        font-size: .7rem;
        font-weight: 700;
        @include text-gradient();
    }
}