@use "../../../styles/index.scss" as globals;

.popover {
    position: absolute;
    padding: 10px 20px;
    background-color: globals.$color_black;
    color: globals.$color_white;
    border-radius: 15px;
    @include globals.box-shadow-default();

    // arrow
    &::before {
        content: '';
        position: absolute;
        height: 15px;
        aspect-ratio: 1;
        background-color: globals.$color_black;
        rotate: 45deg;
        top: -7px;
        right: 45%;
        @include globals.box-shadow-default();
    }
}