@use '../../../styles/control.scss' as control;

.drop-down-menu {
    animation: slide .3s forwards;
    top:100%;
    right:0;
    z-index: 2;
    
    .drop-down-menu-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        gap: 1rem;

        @include control.control-base();

        .text {
            text-wrap: nowrap;
            font-size: 1rem;
        }
    }
}

@keyframes slide {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(23px);
    }
    
}