@use "../../../styles/index.scss" as globals;

.tube {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    box-sizing: border-box;
    width: 11.5%;
    max-width: 11.5%;
    aspect-ratio: 1/4;
    margin: 1.25rem 0;
    transition: margin .4s;
    padding-top: 20px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border: 2px solid globals.$color-platinum;

    // ----------- glass effect 
    background: rgba(255, 255, 255, 0.11);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    // ----------- end glass effect

    box-shadow: 
        inset 0 0 6px 2px lighten($color: globals.$color-black, $amount: 15),
        2px 2px 6px 1px lighten($color: globals.$color-black, $amount: 0);

    &.overflow {
        overflow: hidden;
    }

    &.selected {
        margin: 0 0 2.5rem;
    }

    &.done {
        border-top-width: 10px;
        padding-top: 10px;
    }

    @include globals.for-tablet-portrait-up {
        width: 6%;
        max-width: 6%;
    }
}